import React, { useState } from "react";
import { AiOutlineUserAdd } from "react-icons/ai";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import logoCompany from '../assets/img/logo.png';
import { Link } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import { LoginSchema, SignupSchema } from "../utils/validationSchemas";
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner'
import { signInWithEmailAndPassword, getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useFirestore } from 'reactfire';
import Swal from 'sweetalert2'
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';


const Login = () => {
  const firestore = useFirestore();
  const [isLogin, setIsLogin] = useState(true);

  let navigate = useNavigate();
  const auth = getAuth();
  const [Loading, setLoading] = useState(false)


  const signOut = auth => auth.signOut().then(() => navigate("/login"));

  //login
  const doSignIn = async ({ email, password }) => {
    setLoading(true);
    try {
      const respAuth = await signInWithEmailAndPassword(auth, email, password)


      if (respAuth.user) {
        const user = respAuth.user;
        const ref = doc(firestore, 'Users', user.uid);
        const docSnap = await getDoc(ref);
        if (docSnap.exists()) {
          // console.log("Document data:", docSnap.data());

          if (docSnap.data().active === true) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })

            Toast.fire({
              icon: 'success',
              title: 'Signed in successfully'
            })

            navigate(`/products`)
            return
          }
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `User has been disabled.`,
            footer: '<a href="tel:+1718-585-5400">Contact Us To Solve Your Problem?</a>',
            confirmButtonColor: '#f98c1d'
          })
          signOut(auth)
        } else {
          // doc.data() will be undefined in this case
          alert('User dont have any record')
          // console.log("No such document!");
          signOut(auth)

        }

        // console.log(user);






        setLoading(false);
      }

    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      // console.log(errorCode)
      // console.log(errorMessage)
      setLoading(false);
      // alert(

      //   errorMessage,
      // );
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${errorMessage}`,
        footer: '<a href="tel:+1718-585-5400">Contact Us To Solve Your Problem?</a>',
        confirmButtonColor: '#f98c1d'
      })
    }

  };

  //sign up (registro)
  const doSignUp = async ({
    clientName,
    clientAddress,
    clientPhone,
    email,
    password,
  }) => {
    setLoading(true);
    try {
      const respAuth = await createUserWithEmailAndPassword(auth, email, password)


      if (respAuth.user) {
        const user = respAuth.user;
        await setDoc(doc(firestore, "Users", user.uid), {
          clientName: clientName,
          clientAddress: clientAddress,
          clientPhone: clientPhone,
          email: email,
          password: password,
          active: true,
          createdAt: serverTimestamp(),
          uid: user.uid
        });

        // alert('User Created WELCOME')
        Swal.fire(

          'Good job!',
          'User Created WELCOME Print & Wrap Studio Corp',
          'success'
        )

        navigate(`/`)





        // console.log(user);
        setLoading(false);
      }

    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      // console.log(errorCode)
      // console.log(errorMessage)
      setLoading(false);

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'User Already Exists Try Again!',
        footer: '<a href="tel:+1718-585-5400">Contact Us To Solve Your Problem?</a>',
        confirmButtonColor: '#f98c1d'
      })
      // alert(

      //   errorMessage,
      // );


    }

  };
  if (Loading) {
    return <LoadingSpinner />
  }
  return (
    <div className="h-[100vh] bg-gray-100 text-gray-900 flex justify-center self-center items-center">
      <div className={isLogin ? "container" : "container right-panel-active"}>
        <div
          className={
            !isLogin
              ? "form-container sign-up-container"
              : "form-container sign-up-container2"
          }
        >

          <Formik
            initialValues={{
              clientName: '',
              clientAddress: '',
              clientPhone: '',
              email: '',
              password: '',
              confirm: ''

            }}

            validationSchema={SignupSchema}

            onSubmit={async values => {

              // same shape as initial values

              // console.log(values);
              await doSignUp(values)

            }}
          >
            {({ errors, touched }) => (

              <Form className="flex flex-col items-center justify-center h-full px-4 text-center bg-white">
                <h1 className="text-2xl font-extrabold xl:text-3xl">
                  Create Account
                </h1>

                <div className="social-container"></div>
                <div className="my-2 text-center border-b">
                  <div className="inline-block px-2 text-sm font-medium leading-none tracking-wide text-gray-600 transform translate-y-1/2 bg-white">
                    Or use your email for registration
                  </div>
                </div>
                <Field
                  name="clientName"
                  type="text"
                  placeholder="Name"
                  className="w-full px-8 py-3 my-2 text-sm font-medium placeholder-gray-500 bg-gray-100 border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 focus:bg-white"
                />
                {errors.clientName && touched.clientName ? <div>{errors.clientName}</div> : null}
                <Field
                  name="clientAddress"
                  type="text"
                  placeholder="Address"
                  className="w-full px-8 py-3 my-2 text-sm font-medium placeholder-gray-500 bg-gray-100 border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 focus:bg-white"
                />
                {errors.clientAddress && touched.clientAddress ? <div>{errors.clientAddress}</div> : null}
                <Field
                  name="clientPhone"
                  type="text"
                  placeholder="Phone"
                  className="w-full px-8 py-3 my-2 text-sm font-medium placeholder-gray-500 bg-gray-100 border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 focus:bg-white"
                />
                {errors.clientPhone && touched.clientPhone ? <div>{errors.clientPhone}</div> : null}
                <Field
                  name="email"
                  className="w-full px-8 py-3 my-2 text-sm font-medium placeholder-gray-500 bg-gray-100 border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 focus:bg-white"
                  type="email"
                  placeholder="Email"
                />
                {errors.email && touched.email ? <div>{errors.email}</div> : null}
                <Field
                  name="password"
                  type="password"
                  placeholder="Password"
                  className="w-full px-8 py-4 my-2 text-sm font-medium placeholder-gray-500 bg-gray-100 border border-gray-200 rounded-lg w-f focus:outline-none focus:border-gray-400 focus:bg-white"
                />
                {errors.password && touched.password ? <div>{errors.password}</div> : null}
                <Field
                  name="confirm"
                  type="password"
                  placeholder="confirm"
                  className="w-full px-8 py-4 my-2 text-sm font-medium placeholder-gray-500 bg-gray-100 border border-gray-200 rounded-lg w-f focus:outline-none focus:border-gray-400 focus:bg-white"
                />
                {errors.confirm && touched.confirm ? <div>{errors.confirm}</div> : null}
                <button className="mt-5 tracking-wide font-semibold bg-[#F18F34] text-gray-100 w-full py-4 rounded-lg hover:bg-[#F18F34] transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none">
                  <AiOutlineUserAdd className="w-6 h-6 -ml-2" />
                  <span className="ml-3">Sign Up</span>
                </button>
                <span
                  className="mt-5 tracking-wide font-semibold bg-white text-[#F18F34]0 w-full py-4 rounded-lg hover:bg-[#F18F34] hover:text-white transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none md:hidden"
                  onClick={() => {
                    setIsLogin(!isLogin);
                  }}
                >
                  <span className="ml-3">
                    {isLogin ? "Create Account" : "Sign In"}
                  </span>
                  <FaArrowRight className="w-4 h-4 ml-2" />
                </span>
              </Form>
            )}
          </Formik>
        </div>
        <div className="form-container sign-in-container">
          <div className='pt-5 pl-5'>
            <Link to={'/'} className='flex items-center'>
              <FaArrowLeft />
              <span className="px-2">Home</span>
            </Link>
          </div>
          <Formik
            initialValues={{



              email: '',
              password: ''


            }}

            validationSchema={LoginSchema}

            onSubmit={async values => {

              // same shape as initial values

              // console.log(values);
              await doSignIn(values);

            }}

          >

            {({ errors, touched }) => (
              <Form className="flex flex-col items-center justify-center h-full px-4 text-center bg-white">
                <h1 className="text-2xl font-extrabold xl:text-3xl">Sign in</h1>
                <div className="my-3 text-center border-b">
                  <div className="inline-block px-2 text-sm font-medium leading-none tracking-wide text-gray-600 transform translate-y-1/2 bg-white">
                    or use your account
                  </div>
                </div>
                <Field
                  name='email'
                  type="email"
                  placeholder="Email"
                  className="w-full px-8 py-4 my-2 text-sm font-medium placeholder-gray-500 bg-gray-100 border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 focus:bg-white"
                />
                {errors.email && touched.email ? <div>{errors.email}</div> : null}
                <Field
                  name="password"
                  type="password"
                  autoComplete="on"
                  placeholder="Password"
                  className="w-full px-8 py-4 my-2 text-sm font-medium placeholder-gray-500 bg-gray-100 border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 focus:bg-white"
                />
                {errors.password && touched.password ? <div>{errors.password}</div> : null}
                <button
                  type="submit"
                  className="mt-5 tracking-wide font-semibold bg-[#F18F34] text-gray-100 w-full py-4 rounded-lg hover:bg-[#F18F34] transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none">
                  <AiOutlineUserAdd className="w-6 h-6 -ml-2" />
                  <span className="ml-3">Sign In</span>
                </button>
                <span
                  className="mt-5 tracking-wide font-semibold bg-white text-[#F18F34] w-full py-4 rounded-lg hover:bg-[#F18F34] hover:text-white transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none md:hidden"
                  onClick={() => {
                    setIsLogin(!isLogin);
                  }}
                >
                  <span className="ml-3">
                    {isLogin ? "Create Account" : "Sign In"}
                  </span>
                  <FaArrowRight className="w-4 h-4 ml-2" />
                </span>
              </Form>
            )


            }
          </Formik>

        </div>
        <div className="overlay-container bg-cover bg-[#E74E1C] relative left-[-100%] h-full w-full md:block hidden">

          <div className="overlay">
            <div className="overlay-panel md:px-6 overlay-left">
              <img
                src={logoCompany}
                alt='logo company'
                className="w-[70%] mx-auto pb-5"
              />
              <h1 className="text-[22px] md:text-3xl font-extrabold">
                Welcome Back!
              </h1>
              <p className="hidden md:block">
                To keep connected with us please login with your personal info
              </p>
              <button
                className="mt-5 tracking-wide font-semibold bg-white text-[#F18F34] w-full py-4 rounded-lg hover:bg-[#F18F34] hover:text-white transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                onClick={() => {
                  setIsLogin(!isLogin);
                  // console.log(isLogin);
                }}
              >
                <span className="ml-3">Sign Up</span>
                <FaArrowRight className="w-4 h-4 ml-2" />
              </button>
            </div>
            <div className="overlay-panel md:px-6 overlay-right">
              <img
                src={logoCompany}
                alt='logo company'
                className="w-[70%] mx-auto pb-5"
              />
              <h1 className="text-2xl font-extrabold xl:text-3xl">
                Hello, Welcome!
              </h1>
              <p className="hidden md:block">
                Enter your personal details and start journey with us
              </p>
              <button
                className="mt-5 tracking-wide font-semibold bg-white text-[#F18F34] w-full py-4 rounded-lg hover:bg-[#F18F34] hover:text-white transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                onClick={() => {
                  setIsLogin(!isLogin);
                  // console.log(isLogin);
                }}
              >
                <span className="ml-3">Sign Up</span>
                <FaArrowRight className="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login
