import React from 'react'

const InputsContentEdit = ({ title, subtitle, value, set, min, max }) => {
  return (
    <div className="inputs flex flex-col-reverse space-y-4">
      <input
        type="range"
        value={value}
        min={min}
        max={max}
        onChange={(e) => set(parseFloat(e.target.value))}
      />
      <div className='flex justify-start items-start  pb-2 text-[14px]'>
        <p>{title}</p>
        <input
          type="number"
          value={value}
          min={min}
          max={max}
          onChange={(e) => set(parseFloat(e.target.value))}
        />
        <p className='text-[red] '>{subtitle}</p>
      </div>
    </div>
  )
}

export default InputsContentEdit