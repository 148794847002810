import * as Yup from 'yup';

export  const SignupSchema = Yup.object().shape({

    clientName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    clientAddress: Yup.string()
      .min(10, 'Address Too Short!')
      .required('Required'),
    clientPhone: Yup.string()
      .min(10, 'Phone Number Too Short!')
      .required('Required'),
    email: Yup.string()
    .email('Invalid email')
    .required('Required'),
    password: Yup.string()
    .min(8, 'Password Too Short!')
    .required('Required'),
    confirm: Yup.string().oneOf([Yup.ref("password"), null], "Passwords don't match").required("Confirm Password required"),
 
  });
export  const LoginSchema = Yup.object().shape({

    email: Yup.string()
    .email('Invalid email')
    .required('Required'),
    password: Yup.string()
    .min(8, 'Password Too Short!')
    .required('Required'),
 
  });