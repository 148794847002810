import React, { useEffect, useRef, useState } from 'react'
import { BsJournalArrowUp } from 'react-icons/bs';
import { exportAsImage } from '../utils/exportAsImage';
import Axios from 'axios';
import LoadingSpinner from './LoadingSpinner/LoadingSpinner';
import { useFirestore,  useFirestoreDocDataOnce, useStorage } from 'reactfire';

import { doc, serverTimestamp ,addDoc,collection,updateDoc} from 'firebase/firestore';
import {  ref, uploadString ,getDownloadURL} from "firebase/storage";
import Swal from 'sweetalert2';
import { OrderStatusEnums } from '../utils/utils';
import { useNavigate } from 'react-router-dom';



const ModalOrderNow = ({ isModalOrder, onClose, image, productName, designString, prodId, positionDesignCenter, positionDesignLeft, positionDesignRight, clientId }) => {

        let navigate= useNavigate()

    const firestore = useFirestore();
    const storage = useStorage();
    const refProd = doc(firestore, 'Products', prodId);
    const { status:statusProduct, data: product } = useFirestoreDocDataOnce(refProd);
    const refUser = doc(firestore, 'Users', clientId);
    const {  data: client } = useFirestoreDocDataOnce(refUser);

    // console.log(prodId)
    // console.log(clientId)

    const [cantProd, setCantProd] = useState(0)
    const [totalOrder, setTotalOrder] = useState(0)
    const [saving, setSaving] = useState(false)
    const exportRef = useRef();

    const [fondoCamisa, setFondoCamisa] = useState('')

    const [orderQuentityTxt, setOrderQuentityTxt] = useState('')

    useEffect(() => {

        const fetchData = async () => {
            // setLoading(true);
            try {

                const response = await Axios({
                    url: `${image}`,
                    method: "get",
                    responseType: 'blob'


                });
                var reader = new window.FileReader();
                reader.readAsDataURL(response.data);
                reader.onload = function () {

                    var imageDataUrl = reader.result;
                    // console.log(imageDataUrl)
                    setFondoCamisa(imageDataUrl);
                }
                // console.log(response.data)
                //se convierte la data en el objeto
                // console.log(rpdata)
                // setLoading(false)
            } catch (error) {
                console.log(error);
                // setLoading(false)
            }
        };
        fetchData();
    }, [image])


    if (!isModalOrder) return null;

    const save = async () => {
        setSaving(true)
        let sValue = document.getElementById('txts').value;
        let mValue = document.getElementById('txtm').value;
        let lValue = document.getElementById('txtl').value;
        let xlValue = document.getElementById('txtxl').value;
        let xxlValue = document.getElementById('txt2xl').value;
        let xxxlValue = document.getElementById('txt3xl').value;
        let cntOrden = Number(sValue) + Number(mValue) + Number(lValue) + Number(xlValue) + Number(xxlValue) + Number(xxxlValue);
        
        let orderNote = document.getElementById('orderNote').value;
        if (cntOrden> product.amount) {
            setSaving(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'The total of pieces cannot surpass the existence',
                
                confirmButtonColor: '#f98c1d'
              })
            return;
        }  


        ////////////////////////////////////////// envio y subido de imagen
        const res = await exportAsImage(exportRef.current, "test")
       

        const order = {
            adminId: '',
            address: client.clientAddress,
            clientEmail: client.email,
            clientId: clientId,
            clientName: client.clientName,
            clientPhone: client.clientPhone,
            createdAt: serverTimestamp(),
            design: "",
            orderNote: orderNote,
            orderStatus: OrderStatusEnums.Placed,
            preview: '',
            productName: product.productName,
            orderQuantity: orderQuentityTxt,
            orderTotal: totalOrder,
        }



        console.log(order)


        try {
            //creacion de objeto en firestore
            const respDocRef = await addDoc(
              collection(firestore, "Orders"),
              order
            );
            // console.log("Document written with ID: ", respDocRef.id);
      
            if (respDocRef.id) {
              //almacenado de imagen en firestorage
      
              if (res.length >0) {


                //preview upload
                const previewRed = ref(
                  storage,
                  `Orders/${respDocRef.id}/preview.png}`
                );
      
                await uploadString(previewRed, res, 'data_url');
      
                const linkPreview = await getDownloadURL(previewRed);
      
                // console.log(link);
      
                await updateDoc(respDocRef, {
                  preview: linkPreview,
                });

                // design upload
                const designRef = ref(
                  storage,
                  `Orders/${respDocRef.id}/design.png}`
                );
      
                await uploadString(designRef, designString, 'data_url');
      
                const link = await getDownloadURL(designRef);
      
                // console.log(link);
      
                await updateDoc(respDocRef, {
                  design: link,
                });
      
                
              }
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Order created we will be in touch with you soon or you can call us. Thanks for your patience.",
                showConfirmButton: true,
                
              });
              navigate("/")
      
              setSaving(false);
            }
          } catch (error) {
            //   const errorCode = error.code;
            const errorMessage = error.message;
            console.log(error.message);
            // console.log(errorMessage)
            setSaving(false);
            Swal.fire({
                position: "center",
                icon: "error",
                title: `${errorMessage}`,
                showConfirmButton: false,
                timer: 1500,
              });
          }
       

    }





    const dbSizeTshirt = [
        {
            letra: 's',
            // stock: 0,
        },
        {
            letra: 'm',
            // stock: 8,
        },
        {
            letra: 'l',
            // stock: 12,
        },
        {
            letra: 'xl',
            // stock: '32',
        },
        {
            letra: '2xl',
            // stock: 10,
        },
        {
            letra: '3xl',
            // stock: 0,
        },
    ]


    const calularOrden = () => {
        let sValue = document.getElementById('txts').value;
        let mValue = document.getElementById('txtm').value;
        let lValue = document.getElementById('txtl').value;
        let xlValue = document.getElementById('txtxl').value;
        let xxlValue = document.getElementById('txt2xl').value;
        let xxxlValue = document.getElementById('txt3xl').value;

        let cntOrden = Number(sValue) + Number(mValue) + Number(lValue) + Number(xlValue) + Number(xxlValue) + Number(xxxlValue);
        let resOrden = cntOrden * product.price;
        // console.log('resultado',resOrden)

        // validacion si hay datos en el input
        let txtSizeS = sValue ? 'S: ' + sValue + ' | ' : '';
        let txtSizeM = mValue ? 'M: ' + mValue + ' | ' : '';
        let txtSizeL = lValue ? 'L: ' + lValue + ' | ' : '';
        let txtSizeXL = xlValue ? 'XL: ' + xlValue + ' | ' : '';
        let txtSize2XL = xxlValue ? '2XL: ' + xxlValue + ' | ' : '';
        let txtSize3XL = xxxlValue ? '3XL: ' + xxxlValue + ' | ' : '';

        let txtSize = txtSizeS + txtSizeM + txtSizeL + txtSizeXL + txtSize2XL + txtSize3XL

        setOrderQuentityTxt(txtSize)

        // console.log(dbTxtSize)
        setCantProd(cntOrden)
        return setTotalOrder(resOrden.toFixed(2))
    }





    if (fondoCamisa.length === 0 || statusProduct === "loading") {
        return <LoadingSpinner />
    }

    return (
        <div
            className='fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center'
            id='wrapperOrder'
        >
            <div className='w-[90%] md:w-[70%] flex flex-col'>
                <button
                    className='text-white text-[30px] place-self-end'
                    onClick={() => onClose()}
                >
                    X
                </button>

               {


                    saving === true ? 
                    <div className='bg-white rounded pt-10 h-[90vh] overflow-y-auto styleScroll' >
                        <LoadingSpinner label='Saving Order' />
                        </div>
                    :  <div className='bg-white rounded pt-10 h-[90vh] overflow-y-auto styleScroll' >

                    <div className='px-6 pb-5'>
                        <h1 className='capitalize text-[30px] font-bold'>add quantity</h1>
                    </div>

                    <div className='flex flex-col md:flex-row px-3 pb-5'>
                        <div className='w-full md:w-[30%] pb-10 md:pb-0'>
                            <div className='border rounded-lg shadow-md p-3 mb-4' >
                                {/* <img
                                    src={fondoCamisa}
                                    alt='design'
                                    id='imgId'
                                    ref={exportRef}
                                />
                                <img
                                    src={designString}
                                    alt='design'
                                    className='absolute top-0'
                                /> */}
                                <div
                                    className='bg-contain bg-center bg-no-repeat w-full h-[320px] flex justify-center items-center relative'
                                    style={{ backgroundImage: `url("${fondoCamisa}")` }}
                                    ref={exportRef}
                                >
                                    <img
                                        src={designString}
                                        alt='design'
                                        className={`
                                            absolute z-50
                                            ${positionDesignCenter === 'center' ?
                                                'w-[45%] top-[25%]'
                                                : positionDesignRight === 'right' ?
                                                    'top-[20%] right-[30%] w-[15%] '
                                                    : positionDesignLeft === 'left' ?
                                                        'top-[20%] left-[30%] w-[15%]'
                                                        : null
                                            }
                                        `}

                                    />
                                </div>
                            </div>
                            <div className='px-3'>
                                <h3 className='capitalize text-[#5d5d5d] font-semibold pb-3'>colors available:</h3>
                              
                                <ul  className="flex">
                                        {product.colors.map((color, i) => {
                                          return (
                                            <li
                                              key={i}
                                              style={{
                                                //Poner por el color negro
                                                backgroundColor: color,
                                              }}
                                              className="p-5 rounded-full border"
                                            >
                                              {/* <div className="buttons">
                                                <span
                                                  className="cursor-pointer remove"
                                                  onClick={() => RemoveColor(i)}
                                                >
                                                  <BiTrash className="text-[30px] text-white p-[3px]" />
                                                </span>
                                              </div> */}
                                            </li>
                                          );
                                        })}
                                      </ul>
                            </div>
                        </div>

                        <div className='w-full md:w-[70%] px-5 md:px-10'>
                            <div className='pb-10'>
                                <h2 className='text-[27px] capitalize font-semibold'>
                                    {product.productName}
                                    <span></span>
                                </h2>
                                <h3 className='text-[22px] font-bold'>
                                    ${product.price}
                                    <span className='text-[15px] text-[#707070] px-2 font-medium'>
                                        for 1 units
                                    </span>
                                </h3>
                            </div>

                            <div className='flex flex-wrap gap-5 justify-start items-start pb-5'>
                                {
                                    dbSizeTshirt.map((items, index) => {
                                        return (
                                            <div className='flex flex-col' key={index}>
                                                {
                                                    // aqui es el stock general
                                                    product.amount === 0 ?
                                                        <>
                                                            <label className='uppercase pb-1 pl-3'>{items.letra}</label>
                                                            <input
                                                                id={`txt${items.letra}`}
                                                                type={'number'}
                                                                // placeholder={`stock: ${items.stock}`}
                                                                className='w-[100px] md:w-[120px] border p-3 rounded-full'
                                                                disabled
                                                            />
                                                            <label className='pl-3 text-[#828282] text-[10px] capitalize'>out of stock</label>
                                                        </>
                                                        :
                                                        <div className='flex flex-col'>
                                                            <label className='uppercase pb-1 pl-3'>{items.letra}</label>
                                                            <div>
                                                                <input
                                                                    id={`txt${items.letra}`}
                                                                    type={'number'}
                                                                    // placeholder={`in stock`}
                                                                    className='w-[100px] md:w-[120px] border p-3 rounded-full'
                                                                    min={0}
                                                                    // max={items.stock}
                                                                    onInput={calularOrden}
                                                                />
                                                                <span className="validity"></span>
                                                            </div>

                                                        </div>
                                                }

                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <p className='capitalize pt-5 pb-1'>
                                quantity per size:
                                <span className='pl-3 font-semibold'>
                                    {orderQuentityTxt}
                                </span>
                            </p>
                            <div className='border'>
                                <textarea
                                id='orderNote'
                                    placeholder='Nota:'
                                    className='w-full p-3 resize-y md:resize-none'
                                    rows={4}
                                />
                            </div>

                        </div>
                    </div>

                    <div className='py-10'>
                        <div className='w-4/5 mx-auto flex flex-col-reverse md:flex-row justify-between items-center'>

                            <button
                                type='button'
                                className='flex items-center bg-orange-400 text-white px-5 py-3 rounded-lg'
                                onClick={save}
                            >
                                <span className='pr-3'>
                                    <BsJournalArrowUp />
                                </span>
                                Order Now
                            </button>

                            <div className='flex flex-col items-end'>
                                <div className='flex'>
                                    <h3 className='capitalize text-[17px]'>Cantidad:</h3>
                                    <p className='pl-3 text-[17px]'>{cantProd} <span className='text-[13px]'>cnt</span> </p>
                                </div>
                                <div className='flex'>
                                    <h1 className='text-[27px] font-bold'>Total:</h1>
                                    <p className='text-[30px] pl-3 font-bold text-orange-600'>${totalOrder}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>









               }
            </div>
        </div>
    )
}

export default ModalOrderNow
