import React from 'react'

const ButtonContentEdit = ({ icon, title, setHandle, setOnclick }) => {
    return (
        <div
            className='
                border
                rounded-md
                w-[4em]
                h-[4em]
                flex
                justify-center
                items-center
                ease-in-out
                duration-300
                hover:bg-slate-800
                hover:text-white
                mb-5
                relative
                cursor-pointer
            '
            onClick={setOnclick ? setOnclick : null}
        >
            <div className='flex flex-col justify-center items-center'>
                <p>{React.createElement(icon)}</p>
                <p className='capitalize text-[12px]'>{title}</p>

            </div>
            {
                setHandle ?
                    <input
                        type={'file'}
                        className='absolute w-full h-full opacity-0 cursor-pointer'
                        onChange={setHandle}
                    />
                : null
            }
        </div>
    )
}

export default ButtonContentEdit