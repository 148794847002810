import React, { useState, Component, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import { TransformWrapper, TransformComponent } from "@kokarn/react-zoom-pan-pinch";
import { BiLogInCircle } from 'react-icons/bi';


import { useSigninCheck } from 'reactfire';

import InputsContentEdit from '../components/inputsContentEdit'
import ButtonContentEdit from '../components/buttonContentEdit'
import ModalIcons from '../components/modalIcons'

// icons
import { BsFolderPlus } from 'react-icons/bs'
import { ImZoomIn, ImZoomOut } from 'react-icons/im'
import { BiReset } from 'react-icons/bi'
import { Rnd } from 'react-rnd';
import NavMenu from '../components/navMenu';
import ModalOrderNow from '../components/modalOrderNow';
import { exportAsImage } from '../utils/exportAsImage';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';


//creacion componente para drag
class Box extends Component {
  render() {
    return (
      <div
        className="box"
        style={{
          margin: 0,
          width: '100%',
          height: "100%",
          color: this.props.color,
          fontSize: this.props.fontSize
        }}
      >
        {this.props.children}
      </div>
    )
  }
}

export default function Editar() {

  const { status, data: signinResult } = useSigninCheck();
  const { signedIn, user } = signinResult;

  const location = useLocation();
  const exportRef = useRef();

  // datos extraido del modal de producto
  const { id, img, title, name, styleDesign, positionDesign, colors } = location.state;


  const [imgString, setImgString] = useState('')

  //imagen por defecto
  const imgDefaultLogo = 'https://firebasestorage.googleapis.com/v0/b/searchapp-25415.appspot.com/o/61ac41514e4e0f00d6909795_Your%20Logo%20Herw2.png?alt=media&token=d1137d67-2bc9-49ce-a3dd-fd9b8405eae8'

  // estado de la imagen
  const [imgUpload, setimgUpload] = useState(imgDefaultLogo);


  const [widthIcon, setwidthIcon] = useState(30);
  // const [rotateImg, setRotateImg] = useState(0);
  // const [rotateIcon, setRotateIcon] = useState(0);
  const [colorIcon, setColorIcon] = useState('#000000')
  //estado del modal icon
  const [showModalIcon, setShowModalIcon] = useState(false)
  // estado de la seleccion del icon
  const [selectIcon, setSelectIcon] = useState(null)

  // cerrar design
  const [closeDesign, setCloseDesign] = useState(true)

  const handleCloseDesign = () => {
    return setCloseDesign(!closeDesign)
  }


  // estado del modal order
  const [showModalOrderNow, setShowModalOrderNow] = useState(false)

  const handleModalOrderNow = async () => {
    const res = await exportAsImage(exportRef.current, "test")
    setImgString(res)
    return setShowModalOrderNow(!showModalOrderNow)
  }

  // cerrar icon
  const [closeIcons, setCloseIcons] = useState(false)

  const handleCloseIcons = () => {
    setSelectIcon(null)
    return setCloseIcons(!closeIcons)
  }

  // estado de posicion del diseño
  const [positionDesignCenter, setPositionDesignCenter] = useState(positionDesign)
  const [positionDesignLeft, setPositionDesignLeft] = useState(positionDesign)
  const [positionDesignRight, setPositionDesignRight] = useState(positionDesign)

  // posicion center
  const handlePositionDesignCenter = () => {
    setPositionDesignLeft(null)
    setPositionDesignRight(null)
    setPositionDesignCenter('center')
  }

  // posicion left
  const handlePositionDesignLeft = () => {
    setPositionDesignCenter(null)
    setPositionDesignRight(null)
    setPositionDesignLeft('left')
  }

  // posicion right
  const handlePositionDesignRight = () => {
    setPositionDesignCenter(null)
    setPositionDesignCenter(null)
    setPositionDesignRight('right')
  }

  // seleccion del icon
  const onSelectIcons = (selectIcons) => {
    setSelectIcon(selectIcons)
    setShowModalIcon(false)
  }

  // arreglo de font awesome
  const iconsContent = FaIcons[
    selectIcon
  ]

  // abrir el modal del icon
  const contentIcon = () => {
    setCloseIcons(true)
    return setShowModalIcon(true)
  }

  // cargar la imagen seleccionada
  const handleImgUpload = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setimgUpload(reader.result);
        setCloseDesign(true)
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  if (status === 'loading') {
    return <LoadingSpinner />
  }

  return (
    <>
      <NavMenu />
      <main className='flex items-center justify-center h-full px-0 py-10 md:p-10 '>
        <div className='flex flex-col border w-[90%] mx-auto py-10 px-5'>
          <div className='flex flex-col justify-between pb-5 md:flex-row'>
            <div className='pb-5 md:pb-0'>
              <h1 className='text-[30px] capitalize font-bold'>{title}</h1>
              <div className='flex '>
                <h2 className='capitalize text-[20px] text-[#FF4515]'>
                  {name}
                  <span className='px-1 font-semibold text-[#343434] text-[15px]'>|</span>
                </h2>
                <h2 className='capitalize text-[20px] text-[#FF4515]'>
                  {styleDesign}
                  <span className='px-1 font-semibold text-[#343434] text-[15px]'>|</span>

                </h2>
                <ul id="listColor" className="flex justify-center items-center">
                  {colors.map((color, i) => {
                    return (
                      <li
                        key={i}
                        style={{
                          //Poner por el color negro
                          backgroundColor: color,
                        }}
                        className="mx-[2px] rounded-[4px] w-3 h-3"
                      >
                        {/* <div className="buttons">
                                                <span
                                                className="cursor-pointer remove"
                                                onClick={() => RemoveColor(i)}
                                                >
                                                <BiTrash className="text-[30px] text-white p-[3px]" />
                                                </span>
                                              </div> */}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className='flex flex-col md:flex-row'>
            <div className='w-full md:w-[12%] flex space-x-3 md:space-x-0 flex-row md:flex-col justify-center md:justify-start items-center'>
              <Link
                className='
                border
                rounded-md
                w-[4em]
                h-[4em]
                flex
                justify-center
                items-center
                ease-in-out
                duration-300
                hover:bg-slate-800
                hover:text-white
                mb-5
                relative
                cursor-pointer
                '
                to={'/products'}
              >
                <div className='flex flex-col items-center justify-center'>
                  <p><FaIcons.FaBorderAll /></p>
                  <p className='capitalize text-[12px]'>products</p>

                </div>
              </Link>
              <ButtonContentEdit
                title={'Img'}
                icon={BsFolderPlus}
                setHandle={handleImgUpload}
              />
              <ButtonContentEdit
                title={'Icons'}
                icon={FaIcons.FaIcons}
                setOnclick={() => contentIcon()}
              />


            </div>

            <div className='w-full border'>
              <TransformWrapper
                initialScale={1}
                initialPositionX={200}
                initialPositionY={100}

              >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <div>
                    <div className='flex items-center justify-between p-3'>
                      <div className='flex space-x-5'>
                        <button className='duration-300 ease-in-out hover:text-red-700 hover:font-semibold' onClick={() => zoomIn()}><ImZoomIn /></button>
                        <button className='duration-300 ease-in-out hover:text-red-700 hover:font-semibold' onClick={() => zoomOut()}><ImZoomOut /></button>
                      </div>
                      <button className='duration-300 ease-in-out hover:text-red-700 hover:font-semibold' onClick={() => resetTransform()}><BiReset /></button>
                    </div>
                    <TransformComponent wrapperStyle={{ width: '100%' }}>
                      <div className={'flex justify-center items-center'} >
                        <img src={img} alt="product" className='relative opacity-70' />

                        <div
                          className={`
                        absolute border border-[#f9c4c4] z-50
                        ${positionDesignCenter === 'center' ?
                              'w-[36%] h-[110px] md:h-[250px] top-[20%]'
                              : positionDesignRight === 'right' ?
                                'top-[20%] right-[30%] w-[15%] h-[50px] md:h-[100px]'
                                : positionDesignLeft === 'left' ?
                                  'top-[20%] left-[30%] w-[15%] h-[50px] md:h-[100px]'
                                  : null
                            }
                        `}

                        >
                          <div ref={exportRef} className='w-full h-full'>
                            {
                              closeDesign ?
                                <Rnd
                                  default={{
                                    x: 0,
                                    y: 5,
                                    width: positionDesignLeft || positionDesignRight ? 10 : 100,
                                    height: positionDesignLeft || positionDesignRight ? 10 : 100
                                  }}
                                  minWidth={positionDesignLeft || positionDesignRight ? 40 : 50}
                                  minHeight={positionDesignLeft || positionDesignRight ? 40 : 50}
                                  bounds="parent"
                                  onDrag={(e) => {
                                    e.stopImmediatePropagation();
                                  }}
                                  className='hover:border-dotted hover:border-[#0fe600] text-transparent hover:text-[#0fe600] hover:border-[2px] absolute z-50'
                                >

                                  <Box>
                                    <button
                                      className='absolute z-50 cursor-pointer -top-6 -left-2'
                                      onClick={handleCloseDesign}
                                    >x</button>
                                    <img
                                      src={imgUpload}
                                      alt='images logo'
                                    />
                                  </Box>

                                </Rnd>
                                : null
                            }
                            {
                              closeIcons ?
                                <Rnd
                                  default={{
                                    x: 10,
                                    y: 5,
                                  }}
                                  minWidth={50}
                                  minHeight={50}
                                  bounds="parent"
                                  onDrag={(e) => {
                                    e.stopImmediatePropagation();
                                  }}

                                >
                                  <Box color={colorIcon} fontSize={widthIcon}>
                                    <button
                                      className='absolute -top-4 -left-2 z-50  cursor-pointer text-[17px] text-transparent hover:text-[#e69600]'
                                      onClick={handleCloseIcons}
                                    >x</button>
                                    <div>
                                      {
                                        selectIcon ?
                                          React.createElement(iconsContent)
                                          : null
                                      }
                                    </div>
                                  </Box>
                                </Rnd>

                                : null
                            }
                          </div>


                        </div>
                      </div>
                    </TransformComponent>
                  </div>
                )}
              </TransformWrapper>

            </div>
            <div className='w-full md:w-[15%] pl-3'>
              <div className='pb-4'>
                <p className='pb-3 font-bold capitalize'>Position Design</p>
                <div className='flex justify-between'>
                  <button
                    className={`border rounded-md flex items-center ease-in-out duration-300 bg-transparent hover:bg-slate-800 hover:text-white hover:font-semibold`}

                    onClick={handlePositionDesignLeft}
                  >
                    <span className='text-[10px] p-2'>Left</span>
                  </button>
                  <button
                    className='flex items-center duration-300 ease-in-out bg-transparent border rounded-md hover:bg-slate-800 hover:text-white hover:font-semibold'
                    onClick={handlePositionDesignCenter}
                  >
                    <span className='text-[10px] p-2'>Center</span>
                  </button>
                  <button
                    className='flex items-center duration-300 ease-in-out bg-transparent border rounded-md hover:bg-slate-800 hover:text-white hover:font-semibold'
                    onClick={handlePositionDesignRight}
                  >
                    <span className='text-[10px] p-2'>Right</span>
                  </button>
                </div>
              </div>
              {
                selectIcon ?
                  <div className='pb-4'>
                    <p className='font-bold capitalize'>Config Icon</p>
                    <div className='flex flex-col space-y-3'>
                      <InputsContentEdit
                        title='Size'
                        subtitle='px'
                        value={widthIcon}
                        set={setwidthIcon}
                        min={positionDesignLeft || positionDesignRight ? 20 : 40}
                        max={positionDesignLeft || positionDesignRight ? 60 : 100}
                      />
                      {/* <InputsContentEdit
                        title='Rotate Icon'
                        subtitle='°'
                        value={rotateIcon}
                        set={setRotateIcon}
                        min={-180}
                        max={180}
                      /> */}
                      <div>
                        <h1 className='capitalize'>
                          color:
                          <span className='pl-2 lowercase'>
                            {colorIcon}
                          </span>
                        </h1>
                        <input
                          type={'color'}
                          onChange={(e) => setColorIcon(e.target.value)}
                          className='w-full'
                        />
                      </div>
                    </div>
                  </div>
                  : null
              }

            </div>
          </div>
          <div className='flex justify-end'>
            {
              signedIn !== true ? <Link
                className='bg-[#FF9026] text--slate-900 duration-300 ease-in-out hover:bg-[#d06a0a] hover:text-white  rounded-md px-5 py-3 flex items-center'
                to={'/login'}
              >
                <BiLogInCircle />
                <span className='px-2 '>Login</span>
              </Link> :
                <div
                  className='
                border
                rounded-md
                bg-[#f9891d]
                text-white
                flex
                justify-center
                items-center
                ease-in-out
                duration-300
                hover:bg-slate-800
                hover:text-white
                mt-8
                px-5
                py-3
                cursor-pointer
            '
                  onClick={() => handleModalOrderNow()}
                >
                  <div className='flex justify-center items-center font-bold'>
                    <p className='pr-3'>{React.createElement(FaIcons.FaRegEdit)}</p>
                    <p className='capitalize'>Order Now</p>

                  </div>

                </div>
            }
          </div>
        </div>

        {
          showModalIcon === true ?
            <ModalIcons
              isVisibleModal={showModalIcon}
              onClose={() => setShowModalIcon(false)}
              onSelectIcons={onSelectIcons}
            />
            : null
        }
        {


          showModalOrderNow === true ?
            <ModalOrderNow
              isModalOrder={showModalOrderNow}
              onClose={() => setShowModalOrderNow(false)}
              image={img}
              productName={title}
              designString={imgString}
              prodId={id}
              positionDesignCenter={positionDesignCenter}
              positionDesignLeft={positionDesignLeft}
              positionDesignRight={positionDesignRight}
              clientId={user.uid}
            />
            : null
        }
      </main>

    </>
  );
}
