import React from 'react'
import { Link } from 'react-router-dom';
import { BiLogInCircle } from 'react-icons/bi';
import logoCompany from '../assets/img/logo.png'
import { useSigninCheck } from 'reactfire';
import LoadingSpinner from './LoadingSpinner/LoadingSpinner';
import UserTag from './UserTag';



const NavMenu = () => {

    const { status, data: signinResult } = useSigninCheck();
    const { signedIn, user } = signinResult;

    if (status === 'loading') {
        return <LoadingSpinner />;
    }


    return (
        <div className='py-5 border-b-2 bg-slate-900 '>
            <nav className='flex items-center justify-between w-[90%] mx-auto '>
                <Link to="/" className='w-[40%] md:w-[60%]'>
                    <img
                        src={logoCompany}
                        alt='logo company'
                        className='w-full md:w-[20%]'
                    />
                </Link>
                {


                    signedIn !== true ? <Link
                        className='bg-white md:bg-[#FF9026] text--slate-900 duration-300 ease-in-out hover:bg-[#d06a0a] hover:text-white  rounded-md px-5 py-3 flex items-center'
                        to={'/login'}
                    >
                        <BiLogInCircle />
                        <span className='px-2 '>Login</span>
                    </Link> : <UserTag id={user.uid} />
                }
            </nav>
        </div>
    )
}

export default NavMenu