import {
  // FaCoffee,
  FaTshirt} from 'react-icons/fa'

let produts = [
  // {
  //   id: 1001,
  //   name: "cups",
  //   icon: FaCoffee,
  //   styleProducts: [
  //     {
  //       codProd: 14578,
  //       title: 'cups White',
  //       image: 'https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/App-Imprenta%2Ftasas-white.png?alt=media&token=90b813ba-9762-484e-b760-bd740de0864e',
  //     },
  //     {
  //       codProd: 10235,
  //       title: 'cups White',
  //       image: 'https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/App-Imprenta%2Ftasas-black.png?alt=media&token=1d714a3b-c4a7-4110-b89f-c1717d53f7ff',
  //     },
  //   ],
  // },
  {
    id: 2002,
    name: "t-shirt",
    icon: FaTshirt,
    styleProducts: [
      {
        codProd: 20001,
        title: 'V-shirt-Black',
        image: 'https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/App-Imprenta%2Fcamiseta-V.png?alt=media&token=6197dcbb-8cbd-4ef3-b430-e510476e794e',
        styleDesign: 'men',
        positionDesign: 'center',
      },
      {
        codProd: 20002,
        title: 'V-shirt-White',
        image: 'https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/App-Imprenta%2Fcamiseta-V-white.png?alt=media&token=1ec7d660-011c-45b1-928b-04d0fa4458e6',
        styleDesign: 'men',
        positionDesign: 'left',
      },
      {
        codProd: 20003,
        title: 'round t-shirt',
        image: 'https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/App-Imprenta%2Fcuello-redondo.png?alt=media&token=fb5f89d6-1ecf-4c9a-becc-26aba8235695',
        styleDesign: 'men',
        positionDesign: 'right',
      },
      {
        codProd: 20004,
        title: 'lady round t-shirt',
        image: 'https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/App-Imprenta%2Fcamiseta-dama.png?alt=media&token=43f682a6-4a3f-4a18-958b-92132011b91b',
        styleDesign: 'woman',
        positionDesign: 'center',
      },
      {
        codProd: 20005,
        title: `lady's t-shirt`,
        image: 'https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/App-Imprenta%2Fwoman-redondo-RED.png?alt=media&token=e1d0e4f9-e0d4-40da-a0c9-441f1d328796',
        styleDesign: 'woman',
        positionDesign: 'right',
      },
      {
        codProd: 20006,
        title: `lady's t-shirt`,
        image: 'https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/App-Imprenta%2Fwoman-redondo.png?alt=media&token=b5986e30-75c1-4e79-8c73-2429d7a12f2f',
        styleDesign: 'woman',
        positionDesign: 'left',
      },
    ],
  },
  // {
  //   id: 3002,
  //   name: "sweatshirt",
  //   icon: GiShirt,
  //   styleProducts: [
  //     {
  //       codProd: 30001,
  //       title: 'Con Capote',
  //       image: 'https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/App-Imprenta%2Fsudadera-con-capucha.png?alt=media&token=159120d3-72b3-4023-97ba-6d03a3a71cc7',
  //     },
  //     {
  //       codProd: 30002,
  //       title: 'Sin Capote',
  //       image: 'https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/App-Imprenta%2Fsudadera-sin-capucha.png?alt=media&token=2ba58429-5586-4e8c-92e0-7a3549134efb',
  //     },
  //   ],
  // },
];


export function getProducts() {
  return produts;
}

export function getProduct(name) {
  return produts.find(
    (produts) => produts.name === name
  );
}

export function deleteProduct(id) {
  produts = produts.filter(
    (produts) => produts.id !== id
  );
}