import React from 'react'
import * as FaIcons from 'react-icons/fa';

const ModalIcons = ({ isVisibleModal, onClose, onSelectIcons }) => {

    if (!isVisibleModal) return null;

    return (
        <div
            className='fixed z-50 inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center'
            id='modalIcons'
        >
            <div className='w-[90%] md:w-[50%] flex flex-col'>
                <button
                    className='text-white text-[30px] place-self-end'
                    onClick={() => onClose()}
                >
                    X
                </button>
                <div className='bg-white p-2 rounded'>
                    <h2 className='font-semibold text-[30px] capitalize pb-4 px-3'>select the icon</h2>
                    <div className='
                        flex
                        flex-wrap
                        items-center
                        justify-center
                        h-[65vh]
                        overflow-y-auto
                    '>
                        {
                            Object.entries(FaIcons).map((items, index) => {
                                const selectIcons = items[0]
                                const icons = FaIcons[
                                    items[0]
                                ]
                                return (
                                    <button
                                        key={index}
                                        className='p-3 rounded-md ease-in-out bg-transparent duration-300 hover:bg-slate-800 hover:text-white'
                                        onClick={() => onSelectIcons(selectIcons)}
                                    >
                                        {
                                            React.createElement(icons, { size: "20" })
                                        }
                                    </button>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalIcons