import React from 'react';
import ReactDOM from 'react-dom/client';
import './Styles/index.css';
import './Styles/app.css';
import App from './App';

import { FirebaseAppProvider} from 'reactfire';



const root = ReactDOM.createRoot(document.getElementById('root'));

const firebaseConfig = {
  apiKey: "AIzaSyB4XRW-SXrqcCi3Tf2qWKVG6V4A-EpYWjI",
  authDomain: "printwarpstudioapp-1870e.firebaseapp.com",
  projectId: "printwarpstudioapp-1870e",
  storageBucket: "printwarpstudioapp-1870e.appspot.com",
  messagingSenderId: "510414085015",
  appId: "1:510414085015:web:89c6ddee5a72760e8202d2",
  measurementId: "G-3RECEXM31P"
};

root.render(

  <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={false}>
      <App/>
  </FirebaseAppProvider>
);

