import React, { useState } from 'react'
import { BiLogIn } from "react-icons/bi"
import { useFirestore, useFirestoreDocData, useAuth } from 'reactfire';
// import { useNavigate } from "react-router-dom";
import { doc } from "firebase/firestore"
import LoadingSpinner from './LoadingSpinner/LoadingSpinner';
import Logout from './Logout/Logout';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'

const UserTag = ({ id }) => {

    let navigate = useNavigate()
    let firebase = useFirestore()
    const auth = useAuth();
    const signOut = auth => auth.signOut().then(() => {


        navigate("/")

    });

    const ref = doc(firebase, 'Users', id);

    const { status, data: userData } = useFirestoreDocData(ref, {
        idField: 'id',
    });



    if (status === "loading") {
        <LoadingSpinner />
    }
    // console.log(userData)
    if (userData) {

        if (userData.active === false) {


            // console.log("deactivated")
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `User has been disabled.`,
                footer: '<a href="tel:+1718-585-5400">Contact Us To Solve Your Problem?</a>',
                confirmButtonColor: '#f98c1d'
            })
            signOut(auth)

        }
    }

    // estado del modal order
    const [showModalLogout, setShowModalLogout] = useState(false)

    const handleModalLogout = () => {
        return setShowModalLogout(!showModalLogout)
    }


    return (
        <>
            <button
                onClick={handleModalLogout}
                className="flex self-center items-center text-[#F8901E] md:text-white bg-white md:bg-[#ED4F1C] ease-in-out duration-300 md:hover:bg-[#F9891D] py-3 px-5 rounded-lg capitalize"
            >
                {userData !== undefined ? userData.clientName : "LOADING"}  <BiLogIn />

            </button>
            {
                showModalLogout === true ?
                    <Logout
                        isModalLogout={showModalLogout}
                        onClose={() => setShowModalLogout(false)}
                    />
                    : null
            }
        </>
    )
}

export default UserTag