import React from 'react'
import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi'

const ModalProduct = ({ isVisible, onClose,id, name, title, image, logoCompany, styleDesign, positionDesign, colors }) => {


    if (!isVisible) return null;


    return (
        <div
            className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-25 backdrop-blur-sm'
            id='wrapper'
        >
            <div className='w-[90%] md:w-[60%] flex flex-col'>
                <button
                    className='text-white text-[30px] place-self-end'
                    onClick={() => onClose()}
                >
                    X
                </button>
                <div className='p-2 bg-white rounded'>
                    <div className='p-6'>
                        <div className='pb-5 text-center capitalize md:text-start'>
                            <h2 className='font-bold text-[25px]'>
                                categories: {name}
                            </h2>
                            <div className='flex flex-col md:flex-row'>
                                <p>
                                    Styles: {title}
                                    <span className='px-0 md:px-1 font-semibold text-transparent md:text-[#FF4515]'>|</span>
                                </p>
                                <p>
                                    {styleDesign}
                                    <span className='px-0 md:px-1 font-semibold text-transparent md:text-[#FF4515]'>|</span>
                                </p>
                                <p>{positionDesign}</p>
                            </div>


                        </div>
                        <div className='h-[350px] md:h-[65vh] border overflow-y-auto styleScroll'>
                            <div className='flex flex-wrap justify-center items-center space-x-0 md:space-x-5 h-full md:h-[700px] rounded-md'>
                                <div className='relative flex justify-center'>
                                    <img
                                        src={image}
                                        alt={`image product` + title}
                                        className='w-[100%]'
                                    />
                                    {
                                        styleDesign === 'woman' ?
                                            <img
                                                src={logoCompany}
                                                alt='logo company'
                                                className={
                                                    positionDesign === 'center' ?
                                                        'absolute w-[36%] top-[37%]'
                                                        : positionDesign === 'right' ?
                                                            'absolute top-[25%] right-[30%] w-[18%] '
                                                            : positionDesign === 'left' ?
                                                                'absolute top-[25%]  left-[30%] w-[18%] '
                                                                : null
                                                }
                                            />
                                            :
                                            <img
                                                src={logoCompany}
                                                alt='logo company'
                                                className={
                                                    positionDesign === 'center' ?
                                                        'absolute w-[36%] top-[25%]'
                                                        : positionDesign === 'right' ?
                                                            'absolute top-[25%] right-[30%] w-[18%] '
                                                            : positionDesign === 'left' ?
                                                                'absolute top-[25%]  left-[30%] w-[18%] '
                                                                : null
                                                }
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-end pt-8'>
                            <Link
                                to={'/editar'}
                                state={{ id:id,img: image, title: title, name: name, styleDesign: styleDesign, positionDesign: positionDesign, colors:colors }}
                                className='bg-[#f98c1d] text-white font-semibold px-4 py-2 rounded-lg flex capitalize'
                            >
                                <span className='pr-3'>
                                    <FiEdit />
                                </span>
                                edit design
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalProduct