import html2canvas from "html2canvas";

export const exportAsImage = async (el, imageFileName) => {
    const canvas = await html2canvas(el, {
        backgroundColor: null,
        logging:false
    });
    const image = canvas.toDataURL("image/png", 1.0);

    return image
    // downloadImage(image, imageFileName);
};

const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
};

export const exportAsImage2 = async (el, imageFileName) => {
    const canvas = await html2canvas(el, {
        useCORS: true,
        allowTaint: true,
        logging:false
    });
    const image = canvas.toDataURL("image/png", 1.0);

    downloadImage(image, imageFileName);
    return image
};
