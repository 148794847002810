import React, { useState } from "react";
import Cards from "../components/cards";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

import { collection, query, where, orderBy } from "firebase/firestore";
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';

export default function AllProducts() {
  const firestore = useFirestore();
  const productsCollection = collection(firestore, "Products");
  const [active, setActive] = useState(true);
  const [isAscending, setIsAscending] = useState(false);
  const productsQuery = query(
    productsCollection,

    orderBy("createdAt", isAscending ? "asc" : "desc"),
    where("active", "==", active),


  );
  const { status, data: products } = useFirestoreCollectionData(productsQuery, {
    idField: "id",
  });
  // console.log(products)
  // const EstadosProductos = [

  //   {
  //     label: "Active",
  //     value: true,
  //   },
  //   {
  //     label: "Inactive",
  //     value: false,
  //   },
  // ];

  if (status === 'loading') {
    return <LoadingSpinner />
  }

  return (
    <main style={{ padding: "1rem" }}>
      <h1 className="text-[30px] font-bold pb-5"> All Products</h1>
      {/* opcion de activo y inactivo */}
      {/* <div className="mt-4">
        <label htmlFor="Position" className="block text-gray-700">
          Products Filter
        </label>
        <select


          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 "
          onChange={(e) => {
            const val = e.target.value.toLowerCase() === 'true' ? true : false;
            setActive(val)
          }}
          value={active}
        >
          {EstadosProductos.map((value, i) => {
            return (
              <option key={i} value={value.value}>
                {value.label}
              </option>
            );
          })}
        </select>

      </div> */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-5 gap-y-10">
        {

          products.map((prod, index) => {
            return (
              <Cards
                key={index}
                id={prod.id}
                image={prod.img}
                title={prod.productName}
                name={prod.category}
                styleDesign={prod.gender}
                positionDesign={prod.position}
                colors={prod.colors}
              />
            )
          })

        }
      </div>
    </main>
  );
}