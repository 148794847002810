import React from "react";
import {
  NavLink,
  Outlet,
  useSearchParams,
} from "react-router-dom";
import { getProducts } from "../data";
import { DiGhostSmall } from 'react-icons/di';
import NavMenu from "../components/navMenu";

export default function Categoria() {
  let products = getProducts();
  let [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <NavMenu />
      <div className="block h-full md:flex">
        {/* <nav
          // style={{
          //   borderRight: "solid 1px",
          //   padding: "1rem",
          // }}
          className='p-5 border'
        >
          <input
            value={searchParams.get("filter") || ""}
            onChange={(event) => {
              let filter = event.target.value;
              if (filter) {
                setSearchParams({ filter });
              } else {
                setSearchParams({});
              }
            }}
            placeholder='Search'
            className='border rounded-md p-3 w-full md:w-[250px]'
          />
          <NavLink
            style={({ isActive }) => ({
              color: isActive ? "black" : "red",
            })}
            className='flex items-center my-5 capitalize'
            to={`/products`}
          >
            <span className="pr-3"><DiGhostSmall /></span>

            All Products
          </NavLink>
          {products
            .filter((products) => {
              let filter = searchParams.get("filter");
              if (!filter) return true;
              let name = products.name.toLowerCase();
              return name.startsWith(filter.toLowerCase());
            })
            .map((products) => (
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "red" : "black",
                })}
                className='flex items-center my-5 capitalize'
                to={`/products/${products.name}`}
                key={products.name}
              >
                <span className="pr-3">{React.createElement(products.icon, { size: "20" })}</span>
                {products.name}
              </NavLink>
            ))}

        </nav> */}
        <Outlet />
      </div>
    </>
  );
}