import React from 'react'
import { Link } from "react-router-dom";
// import NavMenu from "./components/navMenu";
import logoCompany from '../assets/img/logo.png'
import { useSigninCheck } from 'reactfire';
import { BiLogInCircle } from 'react-icons/bi';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import UserTag from '../components/UserTag';


const redesSocial = [
  {
    icon: 'facebook-f',
    url: 'https://www.facebook.com/printwrapstudiocorp/',
  },
  {
    icon: 'twitter',
    url: 'https://twitter.com/WrapCorp',
  },
  {
    icon: 'instagram',
    url: 'https://www.instagram.com/365printdepot/',
  },
  {
    icon: 'tiktok',
    url: 'https://www.tiktok.com/@printwrapstudiocorp',
  },
  {
    icon: 'youtube',
    url: 'https://www.youtube.com/channel/UCFAkPAQhUXjugJiJvCt-aLg',
  },
]


const Home = () => {


  const { status, data: signinResult } = useSigninCheck();

  if (status === 'loading') {
    return <LoadingSpinner />;
  }

  const { signedIn, user } = signinResult;
  return (
    <div>
      {/* <h1>Image Edition</h1> */}
      {/* <NavMenu /> */}
    <main
        className="w-full h-screen bg-center bg-no-repeat bg-cover mainContent"
      >
        <div className="w-[90%] h-full mx-auto relative pb-10 md:pb-0">
          <div className="flex flex-col-reverse w-full md:flex-row">
            <div className="w-full pt-10 md:w-1/2">
              <h1 className="text-[40px] md:text-[50px] font-bold uppercase pb-5 md:pb-32 text-[#fff]">Print & Wrap <br /> Studio Corp</h1>
              <p className="pb-10 md:pb-20 pr-0  md:pr-[20%] font-semibold text-[#fff] text-[19px]">
                Contact our team today and get a free estimate for any of the services that we offer. We are more than ready to start working on your projects, our goal is to exceed your expectations and always provide you with the best quality. Contact our professional and reliable company today to save time and money on your upcoming Digital printing service and labeling project!
              </p>
              <div className="flex flex-col space-x-0 space-y-5 md:flex-row md:space-x-5 md:space-y-0">
                <Link to={`/products`} className='rounded-md ease-in-out duration-300 bg-white text-[#0F172A] hover:bg-[#0F172A] hover:text-white px-5 py-3 capitalize text-center font-bold'>
                  Browse designs
                </Link>
                <a
                  href={`https://printwrapstudiocorp.com/`}
                  className='rounded-md ease-in-out duration-300 bg-white text-[#0F172A] hover:bg-[#0F172A] hover:text-white px-5 py-3 capitalize text-center font-bold'
                  target={'_blank'}
                  rel="noreferrer"
                >
                  visit our website
                </a>

              </div>
            </div>
            <div className="relative flex items-center justify-center w-full pt-20 md:w-1/2 md:pt-0">
              <img
                src={logoCompany}
                alt={'logo company'}
              />
              <div className='absolute top-5 right-5'>
                {


                  signedIn !== true ? <Link
                    className='bg-[#FF9026] text--slate-900 duration-300 ease-in-out hover:bg-[#d06a0a] hover:text-white  rounded-md px-5 py-3 flex items-center'
                    to={'/login'}
                  >
                    <BiLogInCircle />
                    <span className='px-2'>Login</span>
                  </Link> : <UserTag id={user.uid} />
                }
              </div>
            </div>
          </div>
          <div className=" flex flex-col items-center w-full md:items-start pt-16">
            <p className="pb-5 font-bold text-white capitalize">Follow Us</p>
            <ul className="flex items-center justify-center space-x-5 md:justify-start md:items-start">
              {redesSocial.map((item, index) => {
                return (
                  <li key={index}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white duration-300 ease-in-out hover:text-black"
                    >
                      <i
                        className={`fab fa-${item.icon}`}
                        aria-hidden="true"
                      />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

      </main>
    </div>
  );
}

export default Home