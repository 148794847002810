import {
  useParams,
  // useNavigate,
  // useLocation,
} from "react-router-dom";
import Cards from "../components/cards";
import {
  getProduct,
  // deleteProduct
} from "../data";

export default function CardProduct() {
  // let navigate = useNavigate();
  // let location = useLocation();
  let params  = useParams();
  let product = getProduct(params.productsId);

  return (
    <main className="p-5">
      <h1 className="text-[30px] font-bold pb-5 capitalize"> our Products: <span className="text-[25px] font-medium">{product.name}</span></h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-5 gap-y-10">
        {
          product.styleProducts.map((prod, index) => {
            return (
              <Cards
                key={index}
                image={prod.image}
                title={prod.title}
                name={product.name}
                styleDesign={prod.styleDesign}
                positionDesign={prod.positionDesign}
              />
            )
          })
        }
      </div>

    </main>
  );
}