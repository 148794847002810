import React, { useState } from 'react'
import ModalProduct from './modalProduct';

import logoCompany from '../assets/img/logo.png';

const Cards = ({ id, name, image, title, positionDesign, styleDesign, colors }) => {

    const [showModal, setShowModal] = useState(false)

    const [tempdata, setTempdata] = useState([]);

    //styleDesign es gender
    //positionDesign es postion

    const getData = (id, name, image, title, styleDesign, positionDesign,colors) => {
        let temData = [id, name, image, title, styleDesign, positionDesign,colors];
        // console.log(temData);
        setTempdata(itme => [1, ...temData]);

        return setShowModal(true)
    }

    return (
        <div className='flex flex-col items-center justify-center border rounded-lg'>
            <div
                className='relative flex justify-center cursor-pointer h-full md:h-[350px] w-full'
                onClick={
                    () => getData(
                        id,
                        image,
                        title,
                        name,
                        styleDesign,
                        positionDesign,
                        logoCompany,
                        colors,
                    )
                }
            >
                <img
                    src={image}
                    alt={"Image product " + title}
                    className='w-full'
                />
                {
                    styleDesign === 'woman' ?
                        <img
                            src={logoCompany}
                            alt='logo company'
                            className={
                                positionDesign === 'center' ?
                                    'absolute w-[36%] top-[37%]'
                                    : positionDesign === 'right' ?
                                        'absolute top-[25%] right-[30%] w-[18%] '
                                        : positionDesign === 'left' ?
                                            'absolute top-[25%]  left-[30%] w-[18%] '
                                            : null
                            }
                        />
                        :
                        <img
                            src={logoCompany}
                            alt='logo company'
                            className={
                                positionDesign === 'center' ?
                                    'absolute w-[36%] top-[25%]'
                                    : positionDesign === 'right' ?
                                        'absolute top-[25%] right-[30%] w-[18%] '
                                        : positionDesign === 'left' ?
                                            'absolute top-[25%]  left-[30%] w-[18%] '
                                            : null
                            }
                        />
                }

            </div>

            <div className='pt-3 pb-5 text-center capitalize'>
                <h4 className='font-semibold text-[18px] '>{title}</h4>
                <p className='text-[15px] md:text-[14px]'>style: {styleDesign}</p>
                <p className='text-[15px] md:text-[14px]'>Position: {positionDesign}</p>
                <ul id="listColor" className="flex justify-center">
                    {colors.map((color, i) => {
                        return (
                            <li
                                key={i}
                                style={{
                                    //Poner por el color negro
                                    backgroundColor: color,
                                }}
                                className="mx-[2px] rounded-[4px] w-3 h-3"
                            >
                                {/* <div className="buttons">
                                                <span
                                                className="cursor-pointer remove"
                                                onClick={() => RemoveColor(i)}
                                                >
                                                <BiTrash className="text-[30px] text-white p-[3px]" />
                                                </span>
                                              </div> */}
                            </li>
                        );
                    })}
                </ul>
            </div>
            <button
                className='px-5 py-3 rounded-lg text-white bg-[#f9891d] mb-5'
                onClick={
                    () => getData(
                        id,
                        image,
                        title,
                        name,
                        styleDesign,
                        positionDesign,
                        logoCompany,
                        colors,
                    )
                }
            >
                View More
            </button>
            {
                showModal === true ? <ModalProduct
                    isVisible={showModal}
                    onClose={() => setShowModal(false)}
                    id={tempdata[1]}
                    image={tempdata[2]}
                    title={tempdata[3]}
                    name={tempdata[4]}
                    styleDesign={tempdata[5]}
                    positionDesign={tempdata[6]}
                    logoCompany={logoCompany}
                    colors={colors}
                />
                    : null
            }
        </div>
    )
}

export default Cards