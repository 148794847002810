import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Editar from "./Routes/editar";
import Categoria from "./Routes/categoria";
import CardProducts from './Routes/cardProducts';
import AllProducts from './Routes/allProducts';
import Login from './Routes/login';
import Home from './Routes/Home';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { initializeFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { FirestoreProvider, AuthProvider, useFirebaseApp, useInitFirestore, StorageProvider } from 'reactfire';
import Logout from './components/Logout/Logout';
import NavMenu from "./components/navMenu";



function App() {

  const app = useFirebaseApp();

  const auth = getAuth(app);
  const { status, data: firestoreInstance } = useInitFirestore(async (firebaseApp) => {
    const db = initializeFirestore(firebaseApp, {});
    await enableIndexedDbPersistence(db);
    return db;
  });






  // console.log(auth.currentUser);

  if (status === 'loading') {

    return <LoadingSpinner />

  }
  return (

    <AuthProvider sdk={auth}>
      <StorageProvider sdk={getStorage(app)}>
        <FirestoreProvider sdk={firestoreInstance}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="login" element={<Login />} />
              <Route path="logout" element={<Logout />} />
              {/* <Route path="/" element={<Design />}> */}

              <Route path="editar" element={<Editar />} />
              <Route path="/products" element={<Categoria />}>
                <Route
                  index
                  element={
                    <main style={{ padding: "1rem" }}>
                      <AllProducts />
                    </main>
                  }
                />
                <Route path=":productsId" element={<CardProducts />} />
              </Route>
              <Route
                path="*"
                element={
                  <div>
                    <NavMenu />
                    <main style={{ padding: "1rem", }}>
                      <p>There's nothing here!</p>
                    </main>
                  </div>
                }
              />
            </Routes>
          </BrowserRouter>
        </FirestoreProvider>
      </StorageProvider>


    </AuthProvider>

  )

}





export default App;
